a.item {
    text-decoration: none;
    color: black!important
}
#gridImage:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    cursor: pointer;
}

.cardImage {
   height: 200px
}